import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { NGXLogger } from 'ngx-logger';

@Injectable({ providedIn: 'root' })
export class LogUpdateService {
  constructor(updates: SwUpdate, logger: NGXLogger) {
    updates.available.subscribe(event => {
      logger.debug('current version is', event.current);
      logger.debug('available version is', event.available);
    });
    updates.activated.subscribe(event => {
      logger.debug('old version was', event.previous);
      logger.debug('new version is', event.current);
    });
  }
}
